import { Modal } from 'antd';
import CloseOutlined from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Paragraph from 'shared/components/Paragraph';
import Heading from 'shared/components/Heading';
import BtnPrimary from 'shared/components/Button/BtnPrimary';
import BtnSecondary from 'shared/components/Button/BtnSecondary';

import './Modal.scss';

const ConfirmModal = (props) => {
  const {
    onCancel,
    onSubmit,
    visible,
    iconColor,
    title,
    subTitle,
    description,
    width,
    loading,
    extraClass
  } = props;
  const { t } = useTranslation()

  return (
    (<Modal
      title={null}
      footer={null}
      open={visible}
      maskStyle={{
        background: 'rgba(0,0,0,0.65)'
      }}
      width={`${width || 500}px`}
      wrapClassName='confirm-modal'
      onCancel={onCancel}
      destroyOnClose
      closeIcon={<CloseOutlined style={{ color: iconColor }} />}
    >
      <div className={`confirm-modal__body ${extraClass}`}>
        {!!subTitle && <Paragraph size='large' weight='bold' className='m-b-40'>{subTitle}</Paragraph>}
        <Heading tag={2} weight='normal' additionalClassNames='m-b-16'>{title}</Heading>
        <Paragraph size='large' className='m-b-36'>{description}</Paragraph>
        <div className='confirm-modal__footer'>
          <BtnSecondary key="back" onClick={onCancel} additionalClassNames='m-r-36' disabled={loading}>
            {t('cancelLabel')}
          </BtnSecondary>
          <BtnPrimary key="submit" onClick={onSubmit} loading={loading} disabled={loading}>
            {t('yesLabel')}
          </BtnPrimary>
        </div>
      </div>
    </Modal>)
  );
};

ConfirmModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ConfirmModal.defaultProps = {
  iconColor: 'white'
};

export default ConfirmModal;
