import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Heading from 'shared/components/Heading';
import Paragraph from 'shared/components/Paragraph';
import AddLink from 'shared/components/TopBar/AddLink';
import BlockLoading from 'shared/components/Spinner/BlockLoading';
import ListLoading from 'shared/components/Spinner/ListLoading';
import {
  fetchEventSponsors,
  uploadSponsorLogo,
  resetSponsorCreateState,
  saveEventSponsor,
  deleteEventSponsor
} from 'features/EventContainer/redux/actions/sponsorsActions';
import Instructions from './Instructions';
import SponsorCard from './SponsorCard';
import AddSponsor from './AddSponsor';
import UploadModal from 'shared/components/UploadModal'
import { getSponsorStatus } from 'utils/services/eventFiles';

const SponsorsManagement = (props) => {
  const { t } = useTranslation();
  const {
    hide,
    previewSponsors,
    sponsors,
    newSponsorCreated,
    isFetchingSponsors,
    scheduleId,
    scheduleError,
  } = props;

  const scheduleLoading = !scheduleId && !scheduleError;
  const disabled = !scheduleId || scheduleError;

  const [visible, setVisible] = useState(false);
  const [selSponsor, setSelSponsor] = useState({});
  const [fileStatus, setFileStatus] = useState([])

  const [uploadModalVisibility, setUploadModalVisibility] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    let getSponsors = async () => {
      let sponsorsStatus = await getSponsorStatus(scheduleId)
      setFileStatus(sponsorsStatus)
    }
    getSponsors()
  }, [])

  useEffect(() => {
    if (newSponsorCreated) {
      const sponsor = sponsors.find((s) => s.id === newSponsorCreated);
      setSelSponsor(sponsor);
      props.resetSponsorCreateState();
    }

    let getSponsors = async () => {
      let sponsorsStatus = await getSponsorStatus(scheduleId)
      setFileStatus(sponsorsStatus)
    }

    if(visible == false){
      getSponsors()
    }
    
  }, [newSponsorCreated, sponsors, visible]);

  const handleEdit = (id) => {
    const sponsor = sponsors.find((s) => s.id === id);
    setSelSponsor(sponsor);
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setSelSponsor({});
    setUploadProgress(0);
  };

  const handleSave = (payload) => {
    props.saveEventSponsor(payload, () => closeModal());
  }

  // Upload modal, disallows user interaction while upload request is on-going
  const renderUploadModal = () => (
    <UploadModal
      wrapClassName='coach-scoring-upload-modal'
      visible={uploadModalVisibility}
      onCancel={() => setUploadModalVisibility(false)}
      uploadProgress={uploadProgress}
    />
  )

  return (
    <div className={`c-event-sponsors--management ${hide ? 'c-event-sponsors--hide' : ''}`}>
      <AddSponsor
        visible={visible}
        sponsor={selSponsor}
        onCancel={closeModal}
        saveSponsor={handleSave}
        uploadSponsorLogo={props.uploadSponsorLogo}
        deleteSponsor={props.deleteEventSponsor}
        setUploadModalVisibility={setUploadModalVisibility}
        setUploadProgress={setUploadProgress}
      />
      <Heading additionalClassNames='m-t-48'>{t('sponsorsLabel')}</Heading>
      <Instructions />
      <div className='c-event-sponsors__order-text'>
        <Paragraph size='large' weight='bold'>
          {t('sponsorOrderLabel')}.
        </Paragraph>
        <Paragraph
          size='large'
          weight='bold'
          className='c-event-sponsors__preview-link'
          {...(disabled
            ? { color: 'silver' }
            : { color: 'blue', onClick: previewSponsors })
          }
        >
          {t('previewPageLabel')}.
        </Paragraph>
      </div>
      <div className='c-event-sponsors__actions'>
        {!!scheduleError && (
          <Paragraph className='m-r-16' size='large' color='red'>Failed to load schedule</Paragraph>
        )}
        <BlockLoading
          loading={scheduleLoading}
          text='Loading schedule'
        />
        <AddLink
          addClick={() => setVisible(true)}
          color='primaryBlue'
          customText={t('addSponsorLabel')}
          disabled={disabled}
        />
      </div>
      <ListLoading
        loading={isFetchingSponsors}
      />
      {sponsors.map((sponsor, i) => (
        <SponsorCard
          fileStatus={fileStatus}
          key={sponsor.id}
          index={i + 1}
          {...sponsor}
          editSponsor={handleEdit}
        />
      ))}
      {renderUploadModal()}
    </div>
  );
};

const mapDispatchToProps = {
  fetchEventSponsors,
  uploadSponsorLogo,
  resetSponsorCreateState,
  saveEventSponsor,
  deleteEventSponsor
};

export default connect(null, mapDispatchToProps)(SponsorsManagement);
